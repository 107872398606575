.App {
  width: 100%;
  height: 100%;
  &.dark{
    background: #f5f5f5;
  }
}

.width100{
  width: 100%;
}
.width50{
  width: 50%;
}

.flex{
  display: flex;
}